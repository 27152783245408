
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import RequestForm from "../../components/RequestFormComponent.vue";

@Component({
  components: {
    RequestForm
  }
})
export default class BlockEditPage extends Vue {
  @Prop(Number) readonly id!: number;

  cancelDialog = false;

  formData: any = {};
  errors = null;

  created() {
    this.fetchRequest();
  }

  fetchRequest() {
    this.$store
      .dispatch("vacation/fetchRequest", this.id)
      .then(({ data }) => {
        this.formData = data.data;
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  cancelRequest() {
    this.$store
      .dispatch("vacation/cancelRequest", this.id)
      .then(() => {
        this.$router.push({ name: "vacation.requests.index" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }
}
